import request from '../utils/http.service';

export const createBusinessProfile = async (param) => {
  let profile = null;

  const res = await request('/me/business-profile', 'POST', param);

  profile = res.data;

  return profile;
};

export const updateBusinessProfile = async (param) => {
  let profile = null;

  const res = await request(`/me/business-profile`, 'PUT', param);
  profile = res.data;

  return profile;
};

export const getBusinessProfile = async () => {
  let profile = null;

  const res = await request('/me/business-profile', 'GET');

  profile = res.data;

  return profile;
};

export const getTestTheFucntion = async () => {
  const res = await request('', '');

  return res;
};
