import { combineReducers } from 'redux';
import imageReducer from './brand-image.reducer';
import profileReducer from './profile.reducer';
import businessProfileReducer from './business-profile.reducer';
import stripeReducer from './stripe-setting.reducer';
import storesReducer from './stores.reducer';
import pricingReducer from './pricing.reducer';
import cardReducer from './credit-card.reducer';
import billReducer from './billing-info.reducer';
import invoiceReducer from './invoice.reducer';
import categoriesReducer from './categories.reducer';
import businessProfilesReducer from './business-profiles.reducer';
import roleReducer from './role.reducer';

export default combineReducers({
  brand: imageReducer,
  auth: profileReducer,
  stripe: stripeReducer,
  business: businessProfileReducer,
  stores: storesReducer,
  pricing: pricingReducer,
  credit: cardReducer,
  billing: billReducer,
  invoice: invoiceReducer,
  categories: categoriesReducer,
  businessProfiles: businessProfilesReducer,
  role: roleReducer
});
