import { call, put, takeEvery } from 'redux-saga/effects';
import * as actions from '../actions/brand-image.action';
import * as api from '../../api/brand-image.api';

function* uploadImage(action) {
  try {
    const result = yield call(api.uploadBrandImage, action.payload);

    if (result) {
      yield put(actions.uploadBrandImageSuccess(result));
    } else {
      yield put(actions.uploadBrandImageFailure(result));
    }
  } catch (error) {
    console.error(error);
  }
}

function* removeImage(action) {
  try {
    const result = yield call(api.removeBrandImage, action.payload);

    if (result) {
      yield put(actions.removeBrandImageSuccess(result));
    } else {
      yield put(actions.removeBrandImageFailure(result));
    }
  } catch (error) {
    console.error(error);
  }
}

function* uploadServoImage(action) {
  try {
    const result = yield call(api.uploadServoImage, action.payload);

    if (result) {
      yield put(actions.uploadServoImageSuccess(result));
    } else {
      yield put(actions.uploadServoImageFailure(result));
    }
  } catch (error) {
    console.error(error);
  }
}

function* removeServoImage(action) {
  try {
    const result = yield call(api.removeServoImage, action.payload);

    if (result) {
      yield put(actions.removeServoImageSuccess(result));
    } else {
      yield put(actions.removeServoImageFailure(result));
    }
  } catch (error) {
    console.error(error);
  }
}

const watchBrandImages = [
  takeEvery(actions.Types.UPLOAD_BRAND_IMAGE_REQUEST, uploadImage),
  takeEvery(actions.Types.REMOVE_BRAND_IMAGE_REQUEST, removeImage),
  takeEvery(actions.Types.UPLOAD_SERVO_IMAGE_REQUEST, uploadServoImage),
  takeEvery(actions.Types.REMOVE_SERVO_IMAGE_REQUEST, removeServoImage),
];

export default watchBrandImages;
