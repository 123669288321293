import { call, put, takeEvery } from 'redux-saga/effects';
import * as actions from '../actions/role.action';
import * as api from '../../api/role.api';

function* getMyRole() {
  try {
    const result = yield call(api.getMyRole);

    if (result) {
      yield put(actions.getMyRoleSuccess(result));
    } else {
      yield put(actions.getMyRoleFailure(result));
    }
  } catch (error) {
    yield put(actions.getMyRoleFailure(error));
  }
}

const watchGetMyRole = [
    takeEvery(actions.Types.GET_MY_ROLE_REQUEST, getMyRole),
  ];
  
  export default watchGetMyRole;
  