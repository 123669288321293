import request from '../utils/http.service';

export const getBusinessProfiles = async () => {
  let profile = null;

  const res = await request('/business-profiles', 'GET');
  console.log("get business profiles", res);

  profile = res.data;

  return profile;
};

export const getSingleBusinessProfile = async (param) => {
  let profile = null;

  const res = await request(`/business-profiles/${param}`, 'GET');
  console.log("get business profiles", res);

  profile = res.data;

  return profile;
};

export const approveDenyBusinessProfile = async (param) => {
  let profile = null;

  const res = await request(`/business-profiles/${param.businessProfileId}/${param.approveDeny}`, 'POST', null);
  console.log("get business profiles", res);

  profile = res.data;

  return profile;
};
