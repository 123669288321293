export const menuList = [
  {
    name: 'Home',
    path: '/',
  },
  {
    name: 'Manage My Categories',
    path: '/categories',
  },
  {
    name: 'Create Category',
    path: '/categories/create-category',
  },
  {
    name: 'Edit Category',
    path: '/categories/edit-category',
  },
  {
    name: 'Manage Business Profiles',
    path: '/business-profiles',
  },
];
