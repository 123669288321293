export const Types = {
  GET_MY_ROLE_REQUEST: 'GET_ROLE_REQUEST',
  GET_MY_ROLE_SUCCESS: 'GET_ROLE_SUCCESS',
  GET_MY_ROLE_FAILURE: 'GET_ROLE_FAILURE',
};

export const getMyRoleRequest = () => ({
  type: Types.GET_MY_ROLE_REQUEST,
  payload: {},
});
export const getMyRoleSuccess = (data) => ({
  type: Types.GET_MY_ROLE_SUCCESS,
  payload: data,
});
export const getMyRoleFailure = (data) => ({
  type: Types.GET_MY_ROLE_FAILURE,
  payload: data,
});
