import axios from 'axios';
import * as msal from '@azure/msal-browser';
import config from '../config/config';

//const tenantSubdomain = config.b2cTenant.split('.')[0];
const instance = `https://${config.b2cInstance}/tfp/`;
const signInAuthority = `${instance}${config.b2cTenant}/${config.b2cSigninPolicy}`;

const configuration = {
  auth: {
    authority: signInAuthority,
    knownAuthorities: [`${config.b2cInstance}`],
    clientId: config.b2cAppID,
    redirectUri: window.location.origin,
    postLogoutRedirectUri: window.location.origin,
    validateAuthority: false,
  },
  // cache: {
  //   cacheLocation: 'localStorage',
  //   storeAuthStateInCookie: false,
  // },
};
const msalInstance = new msal.PublicClientApplication(configuration);

const httpService = async (url, type, data, headers, imageUpload, apiType) => {
  let accessToken = '';

  try {
    var accounts = msalInstance.getAllAccounts();
    let authResult = await msalInstance.acquireTokenSilent({
      scopes: config.b2cScopes,
      account: accounts[0],
    });
    accessToken = authResult.accessToken;
  } catch (e) {
    var authRequest = {
      scopes: config.b2cScopes,
      loginHint: accounts[0].idTokenClaims['upn'], // For v1 endpoints, use upn from idToken claims
    };

    return msalInstance.acquireTokenRedirect(authRequest);
  }

  var apiBaseURL = '';
  if (apiType && apiType === 'servo') {
    apiBaseURL = config.apiServoBaseURL;
  } else {
    apiBaseURL = config.apiBaseURL;
  }
  const api = axios.create({
    baseURL: apiBaseURL,
  });

  const request = {
    method: type,
    url: url,
    headers: {
      Authorization: `bearer ${accessToken}`,
      // 'Access-Control-Allow-Origin': '*.bizcorex.com',
      'Access-Control-Allow-Origin': '*',
      'Ocp-Apim-Subscription-Key': config.appBaseAPIKey,
    },
  };

  if (type !== 'GET') {
    request.data = data;
  }

  if (imageUpload && imageUpload === 'getImage') request.responseType = 'blob';

  if (headers) {
    request.headers = {
      ...request.headers,
      headers,
    };
  }

  return api(request);
};

export default httpService;
