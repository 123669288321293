import React from 'react';
import { NavLink } from 'react-router-dom';
import { Nav, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';

import SubMenu from './sub-menu';
import dashboardRoutes from '../../config/routes';

import Logo from '../../assets/images/logo.png';
import SidebarContainer from './index.style';

const Sidebar = ({ isOpen, toggleMenu }) => {
  const profileState = useSelector((state) => state.auth);

  let profileRoute = dashboardRoutes.filter(
    (route) => route.path === '/create-profile'
  );

//   let visibleRoutes = profileState.profile?.businessProfileId
//     ? dashboardRoutes.filter((route) => route.path !== '/create-profile')
//     : profileRoute;
    let visibleRoutes = dashboardRoutes;

  return (
    <SidebarContainer className={`${isOpen && 'open'}`}>
      <div className="sidebar-header">
        <NavLink exact to="/">
          <Button variant="link">
            <img src={Logo} alt="logo" />
          </Button>
        </NavLink>
        <h5 className="pt-2">Admin Portal</h5>

        <FontAwesomeIcon icon={faTimes} onClick={toggleMenu} />
      </div>

      <Nav className="sidebar-nav flex-column">
        {visibleRoutes.map((menuItem, id) =>
          menuItem.subMenu ? (
            <SubMenu
              key={id}
              title={menuItem.name}
              icon={menuItem.icon}
              items={menuItem.subMenu}
              toggleMenu={toggleMenu}
            />
          ) : (
            <Nav.Item
              key={id}
              onClick={window.innerWidth < 1024 ? toggleMenu : undefined}
            >
              <NavLink
                exact
                className="menu-link"
                to={menuItem.path}
                activeClassName="active"
              >
                {menuItem.icon && <FontAwesomeIcon icon={menuItem.icon} />}
                {menuItem.name}
              </NavLink>
            </Nav.Item>
          )
        )}
      </Nav>
    </SidebarContainer>
  );
};

export default Sidebar;
