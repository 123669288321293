export const Types = {
  GET_CATEGORIES_REQUEST: 'GET_CATEGORIES_REQUEST',
  GET_CATEGORIES_SUCCESS: 'GET_CATEGORIES_SUCCESS',
  GET_CATEGORIES_FAILURE: 'GET_CATEGORIES_FAILURE',

  GET_SINGLE_CATEGORY_REQUEST: 'GET_SINGLE_CATEGORY_REQUEST',
  GET_SINGLE_CATEGORY_SUCCESS: 'GET_SINGLE_CATEGORY_SUCCESS',
  GET_SINGLE_CATEGORY_FAILURE: 'GET_SINGLE_CATEGORY_FAILURE',

  CREATE_CATEGORY_REQUEST: 'CREATE_CATEGORY_REQUEST',
  CREATE_CATEGORY_SUCCESS: 'CREATE_CATEGORY_SUCCESS',
  CREATE_CATEGORY_FAILURE: 'CREATE_CATEGORY_FAILURE',

  UPDATE_CATEGORY_REQUEST: 'UPDATE_CATEGORY_REQUEST',
  UPDATE_CATEGORY_SUCCESS: 'UPDATE_CATEGORY_SUCCESS',
  UPDATE_CATEGORY_FAILURE: 'UPDATE_CATEGORY_FAILURE',

  DELETE_CATEGORY_REQUEST: 'DELETE_CATEGORY_REQUEST',
  DELETE_CATEGORY_SUCCESS: 'DELETE_CATEGORY_SUCCESS',
  DELETE_CATEGORY_FAILURE: 'DELETE_CATEGORY_FAILURE',
};

export const getCategoriesRequest = () => ({
  type: Types.GET_CATEGORIES_REQUEST,
  payload: {},
});
export const getCategoriesSuccess = (data) => ({
  type: Types.GET_CATEGORIES_SUCCESS,
  payload: data,
});
export const getCategoriesFailure = (data) => ({
  type: Types.GET_CATEGORIES_FAILURE,
  payload: data,
});

export const createCategoryRequest = (data) => ({
  type: Types.CREATE_CATEGORY_REQUEST,
  payload: data,
});
export const createCategorySuccess = (data) => ({
  type: Types.CREATE_CATEGORY_SUCCESS,
  payload: data,
});
export const createCategoryFailure = (data) => ({
  type: Types.CREATE_CATEGORY_FAILURE,
  payload: data,
});

export const updateCategoryRequest = (data) => ({
  type: Types.UPDATE_CATEGORY_REQUEST,
  payload: data,
});
export const updateCategorySuccess = (data) => ({
  type: Types.UPDATE_CATEGORY_SUCCESS,
  payload: data,
});
export const updateCategoryFailure = (data) => ({
  type: Types.UPDATE_CATEGORY_FAILURE,
  payload: data,
});

export const getSingleCategoryRequest = (data) => ({
  type: Types.GET_SINGLE_CATEGORY_REQUEST,
  payload: data,
});
export const getSingleCategorySuccess = (data) => ({
  type: Types.GET_SINGLE_CATEGORY_SUCCESS,
  payload: data,
});
export const getSingleCategoryFailure = (data) => ({
  type: Types.GET_SINGLE_CATEGORY_FAILURE,
  payload: data,
});

export const deleteCategoryRequest = (data) => ({
  type: Types.DELETE_CATEGORY_REQUEST,
  payload: data,
});
export const deleteCategorySuccess = (data) => ({
  type: Types.DELETE_CATEGORY_SUCCESS,
  payload: data,
});
export const deleteCategoryFailure = (data) => ({
  type: Types.deleteCategoryFailure,
  payload: data,
});
