import React, { useState, useEffect } from 'react';
import Loader from 'react-loader';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Form, Row, Col } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileUpload } from '@fortawesome/free-solid-svg-icons';
import ImageCard from '../../../components/image-card';
import {
  getSingleCategoryRequest,
  updateCategoryRequest,
} from '../../../redux/actions/categories.action';
import {
  uploadServoImageRequest,
  removeServoImageRequest,
} from '../../../redux/actions/brand-image.action';
import EditCategoryContainer from './index.style';
import { useToasts } from 'react-toast-notifications';
import config from '../../../config/config';

const EditCategory = () => {
  let { categoryId } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const { addToast } = useToasts();

  const companyLogo = useSelector((state) => state.brand);
  const categoriesState = useSelector((state) => state.categories);

  const [showLoader, setShowLoader] = useState(false);
  const [logoImage, setLogoImage] = useState(null);
  const [startUpload, setStartUpload] = useState(false);
  const [imageToRemove, setImageToRemove] = useState(false);
  const [newImageUpload, setNewImageUpload] = useState(false);
  const [categoryToUpload, setCategoryToUpload] = useState(null);
  const [currentCategory, setCurrentCategory] = useState(null);
  const [clickUpdateButton, setClickUpdateButton] = useState(false);

  const { register, handleSubmit, errors, reset, formState } = useForm(
    {}
  );

  useEffect(() => {
    dispatch(getSingleCategoryRequest(categoryId));
  }, [categoryId]);

  useEffect(() => {
    if (companyLogo.isLoading || !categoryToUpload) {
      return;
    }

    let category = categoryToUpload;

    if (companyLogo.image || logoImage) {
      if (companyLogo.image) {
        category = {
          ...category,
          image: companyLogo.image.fileName,
          categoryId: categoryId
        };
      } else if (logoImage?.fileName === category.image) {
        category = {
          ...category,
          image: logoImage?.fileName,
          categoryId: categoryId
        };
      }
    }
    console.log('update1');
    dispatch(updateCategoryRequest(category));
    reset();
  }, [companyLogo.isLoading]);

  useEffect(() => {
    if (!categoriesState.singleCategory) {
      return;
    }

    setCurrentCategory(categoriesState.singleCategory);

    reset({
      ...categoriesState.singleCategory,
    });

    console.log(categoriesState.singleCategory);
    if (categoriesState.singleCategory.image === '') {
      setLogoImage(null);
    } else {
      setLogoImage({
        dataUri: `${config.publicApiBaseURL}/images/${categoriesState.singleCategory.image}`,
        fileName: companyLogo.image,
      });
    }
  }, [categoriesState.singleCategory]);

  useEffect(() => {
    console.log('updated', categoriesState, formState);
    if (categoriesState.error) {
      const showToast = (content) => {
        addToast(content, {
          appearance: 'error',
          autoDismiss: true,
          autoDismissTimeout: 2000,
        });
      };

      showToast(categoriesState.error);

      gotoMainPage();

      return;
    }

    const showToast = (content) => {
      addToast(content, {
        appearance: 'success',
        autoDismiss: true,
        autoDismissTimeout: 2000,
      });
    };
    setShowLoader(categoriesState.loading);

    if (categoriesState.loading || !clickUpdateButton) return;

    showToast('Category updated successfully.');

    gotoMainPage();
  }, [categoriesState.loading]);

  const gotoMainPage = () => {
    setTimeout(() => {
      history.push('/categories');
    }, 2000);
  };

  const onFileChange = (file) => {
    if (!file) return;

    setLogoImage({ dataUri: URL.createObjectURL(file), file: file });
    setNewImageUpload(true);
  };
  const removeImage = () => {
    setStartUpload(false);

    if (!companyLogo.image && !logoImage) {
      return;
    } else if (logoImage) {
      setLogoImage(null);
      return;
    }

    setImageToRemove(logoImage);
    setLogoImage(null);
  };

  const onSubmit = handleSubmit((data) => {
    if (companyLogo.isLoading) {
      return;
    }
    console.log(data);
    let categoryData = null;

    categoryData = {
      ...data,
      categoryId: categoryId,
      image: currentCategory.image,
    };
    console.log(categoryData);
    if (newImageUpload && logoImage) {
      dispatch(uploadServoImageRequest(logoImage.file));
      setStartUpload(true);
      setCategoryToUpload(categoryData);
    } else if (imageToRemove) {
      if (companyLogo && companyLogo.image) {
        console.log('update2');
        dispatch(removeServoImageRequest(companyLogo.image.fileName));
      } else {
        console.log('update3');
        dispatch(removeServoImageRequest(imageToRemove.fileName));
      }
      setCategoryToUpload(categoryData);
    } else {
      console.log('update4');
      dispatch(updateCategoryRequest(categoryData));
      reset({});
    }
    setClickUpdateButton(true);
  });

  return (
    <EditCategoryContainer>
      <Loader loaded={!showLoader} className="spinner" color="#1f343d" />

      <h3 className="mb-4">Edit Category</h3>

      {categoriesState.singleCategory && (
        <Form>
          <Form.Group className="position-relative">
            <div className="d-flex">
              <Form.File
                type="file"
                className="company-brand-upload"
                id="companyBrandUpload"
                custom
              >
                <FontAwesomeIcon icon={faFileUpload} />
                <Form.File.Input
                  onChange={(e) => onFileChange(e.target.files[0])}
                />

                <Loader
                  loaded={startUpload ? !categoriesState.loading : true}
                  className="spinner"
                  color="#1f343d"
                />
              </Form.File>

              <div className="brand-image-list d-flex ml-2">
                {logoImage && (
                  <ImageCard
                    image={logoImage}
                    removeImage={() => removeImage()}
                  />
                )}
              </div>
            </div>
          </Form.Group>
          <Row>
            <Col md={6}>
              <Form.Group controlId="formGroupName">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  isInvalid={errors.name}
                  ref={register({
                    required: 'Name is Required',
                  })}
                />
                {errors.name && (
                  <Form.Control.Feedback type="invalid">
                    {errors.name.message}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="formGroupName">
                <Form.Label>Detail</Form.Label>
                <Form.Control
                  type="text"
                  name="detail"
                  ref={register()}
                  isInvalid={errors.detail}
                />
                {errors.detail && (
                  <Form.Control.Feedback type="invalid">
                    {errors.detail.message}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Col>
          </Row>
          <div className="mt-3">
            <Button
              className="action-control"
              variant="primary"
              onClick={(e) => onSubmit(e)}
            >
              Update
            </Button>
          </div>
        </Form>
      )}
    </EditCategoryContainer>
  );
};

export default EditCategory;
