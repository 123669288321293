import React, { useEffect, useState } from 'react';
import Loader from 'react-loader';
import { useDispatch, useSelector } from 'react-redux';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { NavLink, useHistory, Link } from 'react-router-dom';
import { Button, Table } from 'react-bootstrap';
import { getBusinessProfilesRequest, approveDenyBusinessProfileRequest } from '../../redux/actions/business-profiles.action';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronRight,
  faCheck,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import ApproveModal from '../../components/approve-modal';

const BussinessProfilesPage = () => {
  const dispatch = useDispatch();
  const businessProfilesState = useSelector((state) => state.businessProfiles);

  const [approveModalShow, setApproveModalShow] = useState(false);
  const [selectBusinessProfile, setSelectBusinessProile] = useState({});
  const [approveDeny, setApproveDeny] = useState(true);

  const history = useHistory();

  useEffect(() => {
    dispatch(getBusinessProfilesRequest());
  }, [dispatch]);

  useEffect(() => {
    console.log('business profiles state', businessProfilesState);
  }, [businessProfilesState.loading]);

  const onApprove = (data) => {
    setApproveDeny(true);
    setApproveModalShow(true);
    setSelectBusinessProile(data);
  };

  const onDeny = (data) => {
    setApproveDeny(false);
    setApproveModalShow(true);
    setSelectBusinessProile(data);
  };

  const gotoMainPage = () => {
    setTimeout(() => {
      history.push('/business-profiles');
    }, 2000);
  };

  const onConfirm = () => {
    let businessProfile = {
      ...selectBusinessProfile,
      approveDeny: approveDeny
    }
    dispatch(approveDenyBusinessProfileRequest(businessProfile));
    setApproveModalShow(false);

    gotoMainPage();
  };

  const appInsights = useAppInsightsContext();

  appInsights.trackMetric("Component '/businessProfiles index.js' is in use");

  return (
    <>
      <Loader
        loaded={businessProfilesState ? !businessProfilesState.loading : false}
        className="spinner"
      />

      <div className="d-flex justify-content-between align-items-center">
        <h2 className="mb-0">Manage Business Profiles</h2>
      </div>
      {!businessProfilesState.loading && (
        <>
          <div className="store-list mt-5">
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>PhoneNumber</th>
                  <th>Status</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {!businessProfilesState.loading &&
                  (businessProfilesState.businessProfiles &&
                  businessProfilesState.businessProfiles?.length > 0 ? (
                    businessProfilesState.businessProfiles.map(
                      (businessProfile, id) => (
                        <tr key={businessProfile.businessProfileId}>
                          <td>{businessProfile.businessName}</td>
                          <td>{businessProfile.emailAddress}</td>
                          <td>{businessProfile.phoneNumber}</td>
                          <td>
                            {!!businessProfile.pendingBusinessProfile ? (
                              <>
                                <span style={{ color: 'red' }}>
                                  Waiting Approve
                                </span>
                                <Button
                                  style={{ marginLeft: '15px' }}
                                  variant="danger"
                                  // onClick={() => {setRemoveModalShow(true); setSelectCategory(category)}}
                                  onClick={() => onDeny(businessProfile)}
                                >
                                  <FontAwesomeIcon icon={faTimes} />
                                </Button>
                                <Button
                                  className="float-right"
                                  variant="warning"
                                  // onClick={() => {setRemoveModalShow(true); setSelectCategory(category)}}
                                  onClick={() => onApprove(businessProfile)}
                                >
                                  <FontAwesomeIcon icon={faCheck} />
                                </Button>
                              </>
                            ) : (
                              <span style={{ color: 'green' }}>Approved</span>
                            )}
                          </td>
                          <td align="right">
                            <Link
                              to={`/business-profiles/${businessProfile.businessProfileId}`}
                            >
                              <Button variant="success">
                                View Detail
                                <FontAwesomeIcon
                                  icon={faChevronRight}
                                  style={{ marginLeft: '10px' }}
                                />
                              </Button>
                            </Link>
                          </td>
                        </tr>
                      )
                    )
                  ) : (
                    <tr>
                      <td colSpan="5">
                        You don't have any business profiles yet.!
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </div>
        </>
      )}
      {approveModalShow && (
        <ApproveModal
          show={approveModalShow}
          approveItem={onConfirm}
          itemLabel={'business profile'}
          itemLabelType={approveDeny}
          onClose={() => setApproveModalShow(false)}
        />
      )}
    </>
  );
};

export default BussinessProfilesPage;
