import React, { useState, useEffect } from 'react';
import Loader from 'react-loader';
import { useForm, Controller } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';

import PhoneInput from 'react-phone-input-2';
import {
  parsePhoneNumberFromString,
  isValidNumberForRegion,
} from 'libphonenumber-js';
import AutoComplete from 'react-google-autocomplete';
import { useToasts } from 'react-toast-notifications';
import { Button, Form, Row, Col, InputGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faMapMarkerAlt,
  faEnvelope,
  faGlobe,
  faFileUpload,
  faBuilding,
  faPeopleArrows,
  faClock,
  faDollarSign,
  faArrowLeft,
} from '@fortawesome/free-solid-svg-icons';

import ImageCard from '../../../components/image-card';
import config from '../../../config/config';

import DetailBusinessProfileContainer from './index.style';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { useHistory, useParams } from 'react-router-dom';
import {
  getSingleBusinessProfileRequest,
  getBusinessProfilesRequest,
} from '../../../redux/actions/business-profiles.action';

const DetailBusinessProfile = () => {
  let { businessProfileId } = useParams();

  const waitApprove = true;
  const history = useHistory();
  const dispatch = useDispatch();
  const companyLogo = useSelector((state) => state.brand);
  const businessProfilesState = useSelector((state) => state.businessProfiles);

  const [showLoader, setShowLoader] = useState(false);
  const [startUpload, setStartUpload] = useState(false);
  const [logoImage, setLogoImage] = useState(null);
  const [pendingStatus, setPendingStatus] = useState(false);
  const [pendingProfile, setPendingProfile] = useState({});
  const [currentBusinessProfile, setCurrentBusinessProfile] = useState({
    businessName: '',
    businessShortName: '',
    businessDescription: '',
    businessAddress: {
      streetAddress: '',
      city: '',
      province: '',
      country: '',
      postalCode: '',
      additionalDetails: '',
    },
    emailAddress: '',
    phoneNumber: '',
    website: '',
    serviceRange: '',
    timezone: '',
    currency: '',
  });

  const { register, handleSubmit, errors, reset, formState, control } = useForm(
    {}
  );

  useEffect(() => {
    dispatch(getBusinessProfilesRequest());
  }, [dispatch]);
  useEffect(() => {
    let currentBP = businessProfilesState.businessProfiles?.find(
      (businessProfile) =>
        businessProfile.businessProfileId === businessProfileId
    );
    if (!!currentBP) {
      console.log(currentBP);
      setCurrentBusinessProfile(currentBP);
      if (currentBP.pendingBusinessProfile !== null) {
        setPendingStatus(true);
        setPendingProfile(JSON.parse(currentBP.pendingBusinessProfile));
      }

      if (currentBP.companyBrandings !== undefined && currentBP.companyBrandings[0] !== undefined) {
        setLogoImage({
            dataUri: `${config.publicApiBaseURL}/images/${currentBP.companyBrandings[0].image}`,
            fileName: currentBP.companyBrandings[0].image,
          });    
      }
    } else {
    }
  }, [businessProfileId, businessProfilesState]);

  useEffect(() => {
    console.log('businessProfiles', businessProfilesState);
  }, businessProfilesState.loading);

  useEffect(() => {
    console.log(pendingProfile);
  }, [pendingProfile]);

  const onBack = () => {
    history.push('/business-profiles');
  };

  return (
    <DetailBusinessProfileContainer>
      <Loader loaded={!showLoader} className="spinner" color="#1f343d" />

      {}
      <div>
        <h2 className="title mb-4">
          {currentBusinessProfile.businessName} -{' '}
          {currentBusinessProfile.businessShortName}
        </h2>

        <p className="subtitle">Company Logo</p>

        <Form>
          <Form.Group className="position-relative">
            <div className="d-flex">
              <div className="brand-image-list d-flex ml-2">
                {logoImage && <ImageCard image={logoImage} />}
              </div>
            </div>
          </Form.Group>
          <Row>
            <Col md={6}>
              <Form.Group controlId="formGroupName">
                <Form.Label>Business Name</Form.Label>
                <Form.Control
                  disabled={waitApprove}
                  value={currentBusinessProfile.businessName}
                  type="text"
                  name="businessName"
                />
                {pendingStatus ? (
                  <Form.Control
                    disabled={waitApprove}
                    value={pendingProfile.BusinessName}
                    type="text"
                    name="pendingBusinessName"
                  />
                ) : (
                  ''
                )}
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="formGroupName">
                <Form.Label>Business Short Name</Form.Label>
                <Form.Control
                  disabled={waitApprove}
                  value={currentBusinessProfile.businessShortName}
                  type="text"
                  name="businessName"
                />
                {pendingStatus ? (
                  <Form.Control
                    disabled={waitApprove}
                    value={pendingProfile.BusinessShortName}
                    type="text"
                    name="businessName"
                  />
                ) : (
                  ''
                )}
              </Form.Group>
            </Col>
          </Row>
          <Form.Group controlId="formGroupDescription">
            <Form.Label>Business Description</Form.Label>
            <Form.Control
              disabled={waitApprove}
              as="textarea"
              name="businessDescription"
              value={currentBusinessProfile.businessDescription}
              rows={3}
            />
            {pendingStatus ? (
              <Form.Control
                disabled={waitApprove}
                as="textarea"
                name="businessDescription"
                value={pendingProfile.BusinessDescription}
                rows={3}
              />
            ) : (
              ''
            )}
          </Form.Group>

          <Row>
            <Col md={6}>
              <Form.Group controlId="formGroupAddr">
                <Form.Label>Business Address</Form.Label>
                <InputGroup>
                  <InputGroup.Prepend>
                    <InputGroup.Text>
                      <FontAwesomeIcon icon={faMapMarkerAlt} />
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <Form.Control
                    disabled={waitApprove}
                    value={
                      currentBusinessProfile.businessAddress.streetAddress +
                      ' ' +
                      currentBusinessProfile.businessAddress.city +
                      ' ' +
                      currentBusinessProfile.businessAddress.province +
                      ' ' +
                      currentBusinessProfile.businessAddress.country +
                      ' ' +
                      currentBusinessProfile.businessAddress.postalCode
                    }
                    type="text"
                    name="businessAddress"
                  />
                </InputGroup>
              </Form.Group>
              {pendingStatus ? (
                <Form.Group controlId="formGroupAddr">
                  <InputGroup>
                    <InputGroup.Prepend>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faMapMarkerAlt} />
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control
                      disabled={waitApprove}
                      value={
                        pendingProfile.BusinessAddress.StreetAddress +
                        ' ' +
                        pendingProfile.BusinessAddress.City +
                        ' ' +
                        pendingProfile.BusinessAddress.Province +
                        ' ' +
                        pendingProfile.BusinessAddress.Country +
                        ' ' +
                        pendingProfile.BusinessAddress.PostalCode
                      }
                      type="text"
                      name="businessAddress"
                    />
                  </InputGroup>
                </Form.Group>
              ) : (
                ''
              )}
            </Col>

            <Col md={6}>
              <Form.Group controlId="formGroupAddressDetail">
                <Form.Label>Address Details (Suite, Unit, etc.)</Form.Label>
                <InputGroup>
                  <InputGroup.Prepend>
                    <InputGroup.Text>
                      <FontAwesomeIcon icon={faBuilding} />
                    </InputGroup.Text>
                  </InputGroup.Prepend>

                  <Form.Control
                    disabled={waitApprove}
                    type="text"
                    name="addressDetail"
                    value={
                      currentBusinessProfile.businessAddress.additionalDetails
                    }
                  />
                </InputGroup>
              </Form.Group>
              {pendingStatus ? (
                <Form.Group controlId="formGroupAddressDetail">
                  <InputGroup>
                    <InputGroup.Prepend>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faBuilding} />
                      </InputGroup.Text>
                    </InputGroup.Prepend>

                    <Form.Control
                      disabled={waitApprove}
                      type="text"
                      name="addressDetail"
                      value={pendingProfile.BusinessAddress.AdditionalDetails}
                    />
                  </InputGroup>
                </Form.Group>
              ) : (
                ''
              )}
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <Form.Group controlId="formGroupEmail">
                <Form.Label>Email Address</Form.Label>
                <InputGroup>
                  <InputGroup.Prepend>
                    <InputGroup.Text>
                      <FontAwesomeIcon icon={faEnvelope} />
                    </InputGroup.Text>
                  </InputGroup.Prepend>

                  <Form.Control
                    disabled={waitApprove}
                    name="emailAddress"
                    type="email"
                    value={currentBusinessProfile.emailAddress}
                  />
                </InputGroup>
              </Form.Group>
              {pendingStatus ? (
                <Form.Group controlId="formGroupEmail">
                  <InputGroup>
                    <InputGroup.Prepend>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faEnvelope} />
                      </InputGroup.Text>
                    </InputGroup.Prepend>

                    <Form.Control
                      disabled={waitApprove}
                      name="emailAddress"
                      type="email"
                      value={pendingProfile.EmailAddress}
                    />
                  </InputGroup>
                </Form.Group>
              ) : (
                ''
              )}
            </Col>

            <Col md={6}>
              <Form.Group controlId="formGroupPhone">
                <Form.Label>Phone Number</Form.Label>
                <Controller
                  name="phoneNumber"
                  control={control}
                  render={({ onChange, value, name }) => (
                    <PhoneInput
                      disabled={waitApprove}
                      country={
                        currentBusinessProfile.businessProfile?.businessAddress
                          .countryCode
                      }
                      value={currentBusinessProfile.phoneNumber}
                      inputProps={{ name }}
                      onChange={(tel) => onChange(tel)}
                    />
                  )}
                />
              </Form.Group>
              {pendingStatus ? (
                <Form.Group controlId="formGroupPhone">
                  <Controller
                    name="phoneNumber"
                    control={control}
                    render={({ onChange, value, name }) => (
                      <PhoneInput
                        disabled={waitApprove}
                        country={
                          pendingProfile.BusinessProfile?.BusinessAddress
                            .CountryCode
                        }
                        value={pendingProfile.PhoneNumber}
                        inputProps={{ name }}
                        onChange={(tel) => onChange(tel)}
                      />
                    )}
                  />
                </Form.Group>
              ) : (
                ''
              )}
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <Form.Group controlId="formGroupWebsite">
                <Form.Label>
                  Website (ex. www.your-domain.com, Facebook page, etc)
                </Form.Label>
                <InputGroup>
                  <InputGroup.Prepend>
                    <InputGroup.Text>
                      <FontAwesomeIcon icon={faGlobe} />
                    </InputGroup.Text>
                  </InputGroup.Prepend>

                  <Form.Control
                    disabled={waitApprove}
                    name="website"
                    type="text"
                    value={currentBusinessProfile.website}
                  />
                </InputGroup>
              </Form.Group>
              {pendingStatus ? (
                <Form.Group controlId="formGroupWebsite">
                  <InputGroup>
                    <InputGroup.Prepend>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faGlobe} />
                      </InputGroup.Text>
                    </InputGroup.Prepend>

                    <Form.Control
                      disabled={waitApprove}
                      name="website"
                      type="text"
                      value={pendingProfile.Website}
                    />
                  </InputGroup>
                </Form.Group>
              ) : (
                ''
              )}
            </Col>
            <Col md={6}>
              <Form.Group controlId="formGroupServiceRange">
                <Form.Label>Service Range</Form.Label>
                <InputGroup>
                  <InputGroup.Prepend>
                    <InputGroup.Text>
                      <FontAwesomeIcon icon={faPeopleArrows} />
                    </InputGroup.Text>
                  </InputGroup.Prepend>

                  <Form.Control
                    disabled={waitApprove}
                    type="text"
                    name="serviceRange"
                    value={currentBusinessProfile.serviceRange}
                  />

                  <InputGroup.Append>
                    <InputGroup.Text>km</InputGroup.Text>
                  </InputGroup.Append>
                </InputGroup>
              </Form.Group>
              {pendingStatus ? (
                <Form.Group controlId="formGroupServiceRange">
                  <InputGroup>
                    <InputGroup.Prepend>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faPeopleArrows} />
                      </InputGroup.Text>
                    </InputGroup.Prepend>

                    <Form.Control
                      disabled={waitApprove}
                      type="text"
                      name="serviceRange"
                      value={pendingProfile.ServiceRange}
                    />

                    <InputGroup.Append>
                      <InputGroup.Text>km</InputGroup.Text>
                    </InputGroup.Append>
                  </InputGroup>
                </Form.Group>
              ) : (
                ''
              )}
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Form.Group controlId="formGroupTimezone">
                <Form.Label>Select Timezone</Form.Label>
                <InputGroup>
                  <InputGroup.Prepend>
                    <InputGroup.Text>
                      <FontAwesomeIcon icon={faClock} />
                    </InputGroup.Text>
                  </InputGroup.Prepend>

                  <Form.Control
                    disabled={waitApprove}
                    type="text"
                    name="timezone"
                    value={currentBusinessProfile.timezone}
                  ></Form.Control>
                </InputGroup>
              </Form.Group>
              {pendingStatus ? (
                <Form.Group controlId="formGroupTimezone">
                  <InputGroup>
                    <InputGroup.Prepend>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faClock} />
                      </InputGroup.Text>
                    </InputGroup.Prepend>

                    <Form.Control
                      disabled={waitApprove}
                      type="text"
                      name="timezone"
                      value={pendingProfile.Timezone}
                    ></Form.Control>
                  </InputGroup>
                </Form.Group>
              ) : (
                ''
              )}
            </Col>
            <Col md={6}>
              <Form.Group controlId="formGroupCurrency">
                <Form.Label>Select Currency</Form.Label>
                <InputGroup>
                  <InputGroup.Prepend>
                    <InputGroup.Text>
                      <FontAwesomeIcon icon={faDollarSign} />
                    </InputGroup.Text>
                  </InputGroup.Prepend>

                  <Form.Control
                    disabled={waitApprove}
                    type="text"
                    name="currency"
                    value={currentBusinessProfile.currency}
                  ></Form.Control>
                </InputGroup>
              </Form.Group>
              {pendingStatus ? (
                <Form.Group controlId="formGroupCurrency">
                  <InputGroup>
                    <InputGroup.Prepend>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faDollarSign} />
                      </InputGroup.Text>
                    </InputGroup.Prepend>

                    <Form.Control
                      disabled={waitApprove}
                      type="text"
                      name="currency"
                      value={pendingProfile.Currency}
                    ></Form.Control>
                  </InputGroup>
                </Form.Group>
              ) : (
                ''
              )}
            </Col>
          </Row>
          <Button onClick={onBack}>Back</Button>
        </Form>
      </div>
    </DetailBusinessProfileContainer>
  );
};

export default DetailBusinessProfile;
