import request from '../utils/http.service';

export const getMyRole = async () => {
    let role = null;
  
    const res = await request('/me/role', 'GET');
  
    role = res.data;
  
    return role;
  };