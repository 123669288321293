import React from 'react';
import { Button, Modal } from 'react-bootstrap';

const ApproveModal = ({
  approveItem,
  itemLabel,
  itemLabelType,
  onClose,
  ...props
}) => {
  return (
    <Modal
      {...props}
      onHide={onClose}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Are you sure?
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {itemLabelType ? (
          <p>Are you sure you want to deny this {itemLabel}?</p>
        ) : (
          <p>Are you sure you want to deny this {itemLabel}?</p>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-secondary" onClick={onClose}>
          No, Keep {itemLabel}
        </Button>
        {itemLabelType ? (
          <Button variant="success" onClick={approveItem}>
            Yes, Approve {itemLabel}
          </Button>
        ) : (
          <Button variant="danger" onClick={approveItem}>
            Yes, Deny {itemLabel}
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default ApproveModal;
