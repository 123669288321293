import { Types } from '../actions/role.action';

const initialState = {
  role: null,
  loading: false,
  error: null,
};

const roleReducer = (state = initialState, action) => {
  switch (action.type) {
    case Types.GET_MY_ROLE_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case Types.GET_MY_ROLE_SUCCESS:
      return {
        ...state,
        role: action.payload,
        loading: false,
      };
    case Types.GET_MY_ROLE_FAILURE:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
};

export default roleReducer;
