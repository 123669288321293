import React, { useEffect, useState } from 'react';
import Loader from 'react-loader';
import { Global } from '@emotion/react';
import { useSelector, useDispatch } from 'react-redux';
import { ThemeProvider } from '@emotion/react';
import { ToastProvider } from 'react-toast-notifications';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';
import { LoadScript } from '@react-google-maps/api';

import { AppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { reactPlugin } from './AppInsights';

import config from './config/config';
import theme from './utils/theme';
import PrivateRoute from './utils/private-route';

import './assets/scss/custom.scss';
import GlobalStyles from './assets/styles/global.style';
import 'react-phone-input-2/lib/style.css';

import Navbar from './components/navbar';
import Sidebar from './components/sidebar';
import Footer from './components/footer';
import HomePage from './pages/home';
import BusinessProfilesPage from './pages/business-profiles'
import DetailBusinessProfilePage from './pages/business-profiles/detail-business-profile'

import {
  useIsAuthenticated,
  useMsalAuthentication,
  useMsal,
} from '@azure/msal-react';
import { InteractionType } from '@azure/msal-browser';

import {
  getProfileRequest,
  removeProfileState,
} from './redux/actions/profile.action';
import CatergoriesPage from './pages/categories';
import CreateCategoryPage from './pages/categories/create-category';
import EditCategoryPage from './pages/categories/edit-category';

function App() {
  const dispatch = useDispatch();
  const defaultOpen = window.innerWidth > 1023 ? true : false;
  const [sidebarOpen, setSidebarOpen] = useState(defaultOpen);

  const profileState = useSelector((state) => state.auth);
  const isAuthenticated = useIsAuthenticated();
  const { login, result, error } = useMsalAuthentication(
    InteractionType.Redirect,
    {
      scopes: config.b2cScopes,
    }
  );
  const { instance, accounts, inProgress } = useMsal();

  useEffect(() => {
    if (!accounts[0]) return;
    if (!profileState.profile) {
      dispatch(getProfileRequest());
    }
  }, [accounts]);

  const logout = (e) => {
    dispatch(removeProfileState());
    instance.logout();
  };

  return (
    <>
      <AppInsightsContext.Provider value={reactPlugin}>
        <ThemeProvider theme={theme}>
          <Global styles={GlobalStyles} />

          <Router>
            {isAuthenticated && (
              <>
                <Loader loaded={!profileState.loading} className="spinner" />
                {!profileState.loading && (
                  <>
                    <Sidebar
                      isOpen={sidebarOpen}
                      toggleMenu={() => {
                        setSidebarOpen(!sidebarOpen);
                      }}
                    />
                    <div className={`${sidebarOpen && 'side-open'} main`}>
                      <Navbar
                        logout={logout}
                        sidebarOpen={sidebarOpen}
                        toggleMenu={() => {
                          setSidebarOpen(!sidebarOpen);
                        }}
                      />

                      <ToastProvider>
                        <div className="content">
                          <LoadScript
                            googleMapsApiKey={config.REACT_APP_GOOGLE_KEY}
                            libraries={config.REACT_APP_GOOGLE_LIBRARIES}
                          >
                            <Switch>
                              <PrivateRoute
                                exact
                                path="/"
                                component={HomePage}
                              />
                              <PrivateRoute
                                exact
                                path="/categories"
                                component={CatergoriesPage}
                              />
                              <PrivateRoute
                                exact
                                path="/categories/create-category"
                                component={CreateCategoryPage}
                              />
                              <PrivateRoute
                                exact
                                path="/categories/edit-category/:categoryId"
                                component={EditCategoryPage}
                              />
                              <PrivateRoute
                                exact
                                path="/business-profiles"
                                component={BusinessProfilesPage}
                              />
                              <PrivateRoute
                                exact
                                path="/business-profiles/:businessProfileId"
                                component={DetailBusinessProfilePage}
                              />
                            </Switch>
                          </LoadScript>
                        </div>
                      </ToastProvider>

                      <Footer />
                    </div>
                  </>
                )}
              </>
            )}
          </Router>
        </ThemeProvider>
      </AppInsightsContext.Provider>
    </>
  );
}

export default App;
