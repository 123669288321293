import {
  faUserTie,
  faStoreAlt,
} from '@fortawesome/free-solid-svg-icons';

const dashboardRoutes = [
  {
    name: "Manage Categories",
    path: '/categories',
    icon: faStoreAlt
  },
  {
    name: "Manage Business Profiles",
    path: '/business-profiles',
    icon: faUserTie
  }
];

export default dashboardRoutes;
