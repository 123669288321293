import request from '../utils/http.service';

export const getCategories = async () => {
  let categories = null;

  const res = await request('/categories', 'GET', null, null, null, 'servo');

  categories = res.data;

  return categories;
};

export const createCategory = async (param) => {
  let category = null;
  try {
    const res = await request(
      '/categories',
      'POST',
      param,
      null,
      null,
      'servo'
    );
    category = res.data;
  } catch (err) {
    category = err.data;
  }

  return category;
};

export const updateCategory = async (param) => {
  let category = null;
  try {
    const res = await request(`/categories/${param.categoryId}`, 'PUT', param, null, null, 'servo');
    console.log("update category", res);
    if (res.status === 204) {
        category = param;
    }
    else {
        category = res.data;
    }
  } catch (err) {
    category = err.data;
  }

  return category;
};

export const getSingleCategory = async (param) => {
  let store = null;
  try {
    const res = await request(
      `/categories/${param}`,
      'GET',
      null,
      null,
      null,
      'servo'
    );
    store = res.data;
  } catch (err) {
    store = err.data;
  }

  return store;
};

export const deleteCategory = async (param) => {
  let store = null;
  try {
    const res = await request(
      `/categories/${param}`,
      'DELETE',
      null,
      null,
      null,
      'servo'
    );
    store = res.data;
  } catch (err) {
    store = err.data;
  }

  return store;
};
