import { Types } from '../actions/categories.action';

const initialState = {
  categories: null,
  singleCategory: null,
  loading: false,
  error: null,
};

const categoriesReducer = (state = initialState, action) => {
  switch (action.type) {
    case Types.GET_CATEGORIES_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case Types.GET_CATEGORIES_SUCCESS:
      return {
        ...state,
        categories: action.payload,
        loading: false,
      };
    case Types.GET_CATEGORIES_FAILURE:
      return {
        ...state,
        loading: false,
      };

    case Types.GET_SINGLE_CATEGORY_REQUEST:
      return {
        ...state,
        singleCategory: null,
        loading: true,
      };
    case Types.GET_SINGLE_CATEGORY_SUCCESS:
      return {
        ...state,
        singleCategory: action.payload,
        loading: false,
      };
    case Types.GET_SINGLE_CATEGORY_FAILURE:
      return {
        ...state,
        loading: false,
        error: 'This is not your store.',
      };

    case Types.CREATE_CATEGORY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case Types.CREATE_CATEGORY_SUCCESS:
      return {
        ...state,
        categories: [...state.categories, action.payload],
        loading: false,
      };
    case Types.CREATE_CATEGORY_FAILURE:
      return {
        ...state,
        loading: false,
      };

    case Types.UPDATE_CATEGORY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case Types.UPDATE_CATEGORY_SUCCESS:
      console.log('newCategories', [...state.categories]);
      // let newCategories = [...state.categories].map((category) =>
      //   category.categoryId === action.payload.categoryId ? action.payload : category
      // );
      let newCategories = [...state.categories].filter((category) => {
        return category.categoryId !== action.payload.categoryId;
      });
      console.log('newCategories', newCategories);

      return {
        ...state,
        categories: newCategories,
        loading: false,
      };
    case Types.UPDATE_CATEGORY_FAILURE:
      return {
        ...state,
        loading: false,
      };

    case Types.DELETE_CATEGORY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case Types.DELETE_CATEGORY_SUCCESS:
      console.log('success', action);
      return {
        ...state,
        categories: [...state.categories].filter(
          (category) => category.categoryId !== action.payload.categoryId
        ),
        loading: false,
      };
    case Types.DELETE_CATEGORY_FAILURE:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export default categoriesReducer;
