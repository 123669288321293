import { call, put, takeEvery } from 'redux-saga/effects';
import * as actions from '../actions/business-profiles.action';
import * as api from '../../api/business-profiles.api';

function* getBusinessProfiles() {
  try {
    const result = yield call(api.getBusinessProfiles);

    if (result) {
      yield put(actions.getBusinessProfilesSuccess(result));
    } else {
      yield put(actions.getBusinessProfilesFailure(result));
    }
  } catch (error) {
    yield put(actions.getBusinessProfilesFailure(error));
  }
}

function* approveDenyBusinessProfile(action) {
  try {
    const result = yield call(api.approveDenyBusinessProfile, action.payload);

    if (result) {
      yield put(actions.getBusinessProfilesSuccess(result));
    } else {
      yield put(actions.getBusinessProfilesFailure(result));
    }
  } catch (error) {
    yield put(actions.getBusinessProfilesFailure(error));
  }
}

function* getSingleBusinessProfile(action) {
  try {
    const result = yield call(api.getSingleBusinessProfile, action.payload);

    if (result) {
      yield put(actions.getSingleBusinessProfileRequest(result));
    } else {
      yield put(actions.getSingleBusinessProfileFailure(result));
    }
  } catch (error) {
    yield put(actions.getSingleBusinessProfileFailure(error));
  }
}


const watchGetBusinessProfiles = [
  takeEvery(actions.Types.GET_BUSINESS_PROFILES_REQUEST, getBusinessProfiles),
  takeEvery(actions.Types.APPROVE_DENY_BUSINESS_PROFILE_REQUEST, approveDenyBusinessProfile),
  takeEvery(actions.Types.GET_SINGLE_BUSINESS_PROFILE_REQUEST, getSingleBusinessProfile),
];

export default watchGetBusinessProfiles;
