export const Types = {
  UPLOAD_BRAND_IMAGE_REQUEST: 'UPLOAD_BRAND_IMAGE_REQUEST',
  UPLOAD_BRAND_IMAGE_SUCCESS: 'UPLOAD_BRAND_IMAGE_SUCCESS',
  UPLOAD_BRAND_IMAGE_FAILURE: 'UPLOAD_BRAND_IMAGE_FAILURE',

  UPLOAD_SERVO_IMAGE_REQUEST: 'UPLOAD_SERVO_IMAGE_REQUEST',
  UPLOAD_SERVO_IMAGE_SUCCESS: 'UPLOAD_SERVO_IMAGE_SUCCESS',
  UPLOAD_SERVO_IMAGE_FAILURE: 'UPLOAD_SERVO_IMAGE_FAILURE',

  REMOVE_BRAND_IMAGE_REQUEST: 'REMOVE_BRAND_IMAGE_REQUEST',
  REMOVE_BRAND_IMAGE_SUCCESS: 'REMOVE_BRAND_IMAGE_SUCCESS',
  REMOVE_BRAND_IMAGE_FAILURE: 'REMOVE_BRAND_IMAGE_FAILURE',

  REMOVE_SERVO_IMAGE_REQUEST: 'REMOVE_SERVO_IMAGE_REQUEST',
  REMOVE_SERVO_IMAGE_SUCCESS: 'REMOVE_SERVO_IMAGE_SUCCESS',
  REMOVE_SERVO_IMAGE_FAILURE: 'REMOVE_SERVO_IMAGE_FAILURE',
};

export const uploadBrandImageRequest = (data) => ({
  type: Types.UPLOAD_BRAND_IMAGE_REQUEST,
  payload: data,
});
export const uploadBrandImageSuccess = (data) => ({
  type: Types.UPLOAD_BRAND_IMAGE_SUCCESS,
  payload: data,
});
export const uploadBrandImageFailure = (data) => ({
  type: Types.UPLOAD_BRAND_IMAGE_FAILURE,
  payload: data,
});

export const uploadServoImageRequest = (data) => ({
  type: Types.UPLOAD_SERVO_IMAGE_REQUEST,
  payload: data,
});
export const uploadServoImageSuccess = (data) => ({
  type: Types.UPLOAD_SERVO_IMAGE_SUCCESS,
  payload: data,
});
export const uploadServoImageFailure = (data) => ({
  type: Types.UPLOAD_SERVO_IMAGE_FAILURE,
  payload: data,
});

export const removeBrandImageRequest = (id) => ({
  type: Types.REMOVE_BRAND_IMAGE_REQUEST,
  payload: id,
});
export const removeBrandImageSuccess = (data) => ({
  type: Types.REMOVE_BRAND_IMAGE_SUCCESS,
  payload: data,
});
export const removeBrandImageFailure = (data) => ({
  type: Types.REMOVE_BRAND_IMAGE_FAILURE,
  payload: data,
});

export const removeServoImageRequest = (id) => ({
  type: Types.REMOVE_SERVO_IMAGE_REQUEST,
  payload: id,
});
export const removeServoImageSuccess = (data) => ({
  type: Types.REMOVE_SERVO_IMAGE_SUCCESS,
  payload: data,
});
export const removeServoImageFailure = (data) => ({
  type: Types.REMOVE_SERVO_IMAGE_FAILURE,
  payload: data,
});
