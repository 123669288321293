import React, { useEffect, useState } from 'react';
import Loader from 'react-loader';
import { useDispatch, useSelector } from 'react-redux';
import { getCategoriesRequest, deleteCategoryRequest } from '../../redux/actions/categories.action';

import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import CategoriesContainer from './index.style';

import { Button, Table } from 'react-bootstrap';
import { NavLink, useHistory, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import RemoveModal from '../../components/remove-modal';
import config from '../../config/config';

const CatergoriesPage = () => {
  const dispatch = useDispatch();
  const categoriesState = useSelector((state) => state.categories);
  const [removeModalShow, setRemoveModalShow] = useState(false);
  const [selectCategory, setSelectCategory] = useState({});
  const history = useHistory();
  const servoImageURL = `${config.publicApiBaseURL}/images/`;

  const appInsights = useAppInsightsContext();

  appInsights.trackMetric("Component '/catergories index.js' is in use");

  useEffect(() => {
    dispatch(getCategoriesRequest());
  }, [dispatch]);

  const onDelete = () => {
    dispatch(deleteCategoryRequest(selectCategory.categoryId));
    setRemoveModalShow(false);

    gotoMainPage();
  };

  const gotoMainPage = () => {
    setTimeout(() => {
      history.push('/categories');
    }, 2000);
  };

  const onRemove = (category) => {
    setRemoveModalShow(true);
    setSelectCategory(category);
  }

  const displayImage = (url) => {
      console.log(url);
      if (url === "" || url === null) {
          return "";
      }
      else {
        return (<span><img src={servoImageURL + url} width='50' height='50' /></span>);
      }
  }

  return (
    <CategoriesContainer>
      <Loader
        loaded={categoriesState ? !categoriesState.loading : false}
        className="spinner"
      />

      <div className="d-flex justify-content-between align-items-center">
        <h2 className="mb-0">Manage Categories</h2>
        <NavLink to={`/categories/create-category/`}>
          <Button variant="danger">
            <FontAwesomeIcon icon={faPlus} />
          </Button>
        </NavLink>
      </div>
      {!categoriesState.loading && (
        <>
          <div className="store-list mt-5">
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Category ID</th>
                  <th>Name</th>
                  <th>Detail</th>
                  <th>Image</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {!categoriesState.loading &&
                  (categoriesState.categories && categoriesState.categories?.length > 0 ? (
                    categoriesState.categories.map((category, id) => (
                      <tr key={category.categoryId}>
                        <td>{category.categoryId}</td>
                        <td>{category.name}</td>
                        <td>{category.detail}</td>
                        <td>{category.image}{displayImage(category.image)}</td>
                        <td>
                          <Link to={`/categories/edit-category/${category.categoryId}`}>
                            <Button variant="success">
                              <FontAwesomeIcon icon={faEdit} />
                            </Button>
                          </Link>
                          <Button className="float-right" variant="warning"
                            // onClick={() => {setRemoveModalShow(true); setSelectCategory(category)}}
                            onClick={() => onRemove(category)}
                          >
                            <FontAwesomeIcon icon={faTrash} />
                          </Button>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="5">You don't have any categories yet. Create one now!</td>
                    </tr>
                  ))}
              </tbody>
            </Table>

          </div>
        </>
      )}
      {removeModalShow && (
        <RemoveModal
          show={removeModalShow}
          removeItem={onDelete}
          itemLabel={"category"}
          onClose={() => setRemoveModalShow(false)}
        />
      )}

    </CategoriesContainer>
  );
};

export default CatergoriesPage;
