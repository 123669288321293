import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { useMsal } from '@azure/msal-react';
import { getMyRoleRequest } from '../../redux/actions/role.action';

const HomePage = () => {
  const { instance } = useMsal();
  const history = useHistory();

  const appInsights = useAppInsightsContext();
  const dispatch = useDispatch();
  const roleState = useSelector((state) => state.role);

  appInsights.trackMetric("Component '/home' is in use");

  useEffect(() => {
      console.log("role request");
    dispatch(getMyRoleRequest());
  }, [dispatch]);

  useEffect(() => {
    if (roleState.loading === false) {
      if (roleState.role !== null) {
        if (roleState.role.role === 'SuperAdmin') {
          history.push('/categories');
        } else {
          instance.logout();
        }
      }
    }
  }, [roleState.loading]);

  return <div></div>;
};

export default HomePage;
