import { Types } from '../actions/business-profiles.action';

const initialState = {
  businessProfiles: null,
  singleBusinessProfile: null,
  loading: false,
  error: null,
};

const businessProfilesReducer = (state = initialState, action) => {
  console.log(state, action);
  switch (action.type) {
    case Types.GET_BUSINESS_PROFILES_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case Types.GET_BUSINESS_PROFILES_SUCCESS:
      return {
        ...state,
        businessProfiles: action.payload,
        loading: false,
      };
    case Types.GET_BUSINESS_PROFILES_FAILURE:
      return {
        ...state,
        loading: false,
      };

    case Types.GET_SINGLE_BUSINESS_PROFILE_REQUEST:
      return {
        ...state,
        singleBusinessProfile: null,
        loading: true,
      };
    case Types.GET_SINGLE_BUSINESS_PROFILE_SUCCESS:
      return {
        ...state,
        singleBusinessProfile: action.payload,
        loading: false,
      };
    case Types.GET_SINGLE_BUSINESS_PROFILE_FAILURE:
      return {
        ...state,
        loading: false,
        error: 'This is not your store.',
      };

    case Types.APPROVE_DENY_BUSINESS_PROFILE_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case Types.APPROVE_DENY_BUSINESS_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case Types.APPROVE_DENY_BUSINESS_PROFILE_FAILURE:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export default businessProfilesReducer;
