import React, { useState, useEffect } from 'react';
import Loader from 'react-loader';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Form, Row, Col } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileUpload } from '@fortawesome/free-solid-svg-icons';
import ImageCard from '../../../components/image-card';
import CreateCategoryContainer from './index.style';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { useToasts } from 'react-toast-notifications';
import { createCategoryRequest } from '../../../redux/actions/categories.action';
import {
  uploadServoImageRequest,
  removeServoImageRequest,
} from '../../../redux/actions/brand-image.action';

const CreateCategory = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { addToast } = useToasts();

  const categoriesState = useSelector((state) => state.categories);
  const companyLogo = useSelector((state) => state.brand);

  const [showLoader, setShowLoader] = useState(false);
  const [logoImage, setLogoImage] = useState(null);
  const [startUpload, setStartUpload] = useState(false);
  const [imageToRemove, setImageToRemove] = useState(false);
  const [newImageUpload, setNewImageUpload] = useState(false);
  const [categoryToUpload, setCategoryToUpload] = useState(null);

  const { register, handleSubmit, errors, reset, formState } = useForm({});

  const appInsights = useAppInsightsContext();

  appInsights.trackMetric("Component '/edit-store index.js' is in use");

  useEffect(() => {

    if (categoriesState.error) {
      const showToast = (status, content) => {
        addToast(content, {
          appearance: status,
          autoDismiss: true,
          autoDismissTimeout: 3000,
        });
      };

      showToast(
        'error',
        `Profile creation has failed due to error ${categoriesState.error}.`
      );
      setShowLoader(false);
    } else {
      const showToast = (content) => {
        addToast(content, {
          appearance: 'success',
          autoDismiss: true,
          autoDismissTimeout: 3000,
        });
      };
      setShowLoader(categoriesState.loading);

      console.log("create category success status", categoriesState.loading, formState.submitCount);
      if (categoriesState.loading || formState.submitCount === 0) return;

      showToast('Category successfully created.');

      setTimeout(() => {
        history.push('/categories');
      }, 2000);
    }
  }, [categoriesState.loading]);

  useEffect(() => {
    if (companyLogo.isLoading || !categoryToUpload) {
      return;
    }

    let category = categoryToUpload;

    if (companyLogo?.image?.fileName) {
      category = {
        ...category,
        image: companyLogo.image.fileName,
      };
    }
    console.log(category);
    dispatch(createCategoryRequest(category));
  }, [companyLogo.isLoading]);

  const onSubmit = handleSubmit((data) => {
    if (companyLogo.isLoading) {
      return;
    }
    console.log(data);
    let storeData = null;

    storeData = {
      ...data,
    };
    console.log(storeData);
    if (newImageUpload && logoImage) {
      dispatch(uploadServoImageRequest(logoImage.file));
      setStartUpload(true);
      setCategoryToUpload(storeData);
    } 
    else if (imageToRemove) {
      if (companyLogo && companyLogo.image) {
        dispatch(removeServoImageRequest(companyLogo.image.fileName));
      } else {
        dispatch(removeServoImageRequest(imageToRemove.fileName));
      }
      setCategoryToUpload(storeData);
    } 
    else {
      dispatch(createCategoryRequest(storeData));
      reset({});
    }
  });

  const onFileChange = (file) => {
    if (!file) return;
    setLogoImage({ dataUri: URL.createObjectURL(file), file: file });
    setNewImageUpload(true);
  };

  const removeImage = () => {
    setStartUpload(false);

    if (!logoImage) {
      return;
    }

    setLogoImage(null);
  };

  return (
    <CreateCategoryContainer>
      <Loader loaded={!showLoader} className="spinner" color="#1f343d" />

      <h3 className="mb-4">Create Category</h3>

      <Form>
        <Row>
          <Form.Group className="position-relative">
            <div className="d-flex">
              <Form.File
                type="file"
                className="company-brand-upload"
                id="companyBrandUpload"
                custom
              >
                <FontAwesomeIcon icon={faFileUpload} />
                <Form.File.Input
                  onChange={(e) => onFileChange(e.target.files[0])}
                />

                {/* <Loader
                  loaded={startUpload}
                  className="spinner"
                  color="#1f343d"
                /> */}
              </Form.File>

              <div className="brand-image-list d-flex ml-2">
                {logoImage && (
                  <ImageCard
                    image={logoImage}
                    removeImage={() => removeImage()}
                  />
                )}
              </div>
            </div>
          </Form.Group>
        </Row>
        <Row>
          <Col md={6}>
            <Form.Group controlId="formGroupName">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                name="name"
                isInvalid={errors.name}
                ref={register({
                  required: 'Name is Required',
                })}
              />
              {errors.name && (
                <Form.Control.Feedback type="invalid">
                  {errors.name.message}
                </Form.Control.Feedback>
              )}
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group controlId="formGroupDetail">
              <Form.Label>Detail</Form.Label>
              <Form.Control
                type="text"
                name="detail"
                isInvalid={errors.detail}
                ref={register()}
              />
              {errors.detail && (
                <Form.Control.Feedback type="invalid">
                  {errors.detail.message}
                </Form.Control.Feedback>
              )}
            </Form.Group>
          </Col>
        </Row>

        <div className="mt-3">
          <Button
            className="action-control"
            variant="primary"
            onClick={(e) => onSubmit(e)}
          >
            Create
          </Button>
        </div>
      </Form>
    </CreateCategoryContainer>
  );
};

export default CreateCategory;
