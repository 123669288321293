export const Types = {
  GET_BUSINESS_PROFILES_REQUEST: 'GET_BUSINESS_PROFILES_REQUEST',
  GET_BUSINESS_PROFILES_SUCCESS: 'GET_BUSINESS_PROFILES_SUCCESS',
  GET_BUSINESS_PROFILES_FAILURE: 'GET_BUSINESS_PROFILES_FAILURE',

  GET_SINGLE_BUSINESS_PROFILE_REQUEST: 'GET_SINGLE_BUSINESS_PROFILE_REQUEST',
  GET_SINGLE_BUSINESS_PROFILE_SUCCESS: 'GET_SINGLE_BUSINESS_PROFILE_SUCCESS',
  GET_SINGLE_BUSINESS_PROFILE_FAILURE: 'GET_SINGLE_BUSINESS_PROFILE_FAILURE',

  APPROVE_DENY_BUSINESS_PROFILE_REQUEST:
    'APPROVE_DENY_BUSINESS_PROFILE_REQUEST',
  APPROVE_DENY_BUSINESS_PROFILE_SUCCESS:
    'APPROVE_DENY_BUSINESS_PROFILE_SUCCESS',
  APPROVE_DENY_BUSINESS_PROFILE_FAILURE:
    'APPROVE_DENY_BUSINESS_PROFILE_FAILURE',
};

export const getBusinessProfilesRequest = () => ({
  type: Types.GET_BUSINESS_PROFILES_REQUEST,
  payload: {},
});
export const getBusinessProfilesSuccess = (data) => ({
  type: Types.GET_BUSINESS_PROFILES_SUCCESS,
  payload: data,
});
export const getBusinessProfilesFailure = (data) => ({
  type: Types.GET_BUSINESS_PROFILES_FAILURE,
  payload: data,
});

export const getSingleBusinessProfileRequest = (data) => ({
  type: Types.GET_SINGLE_BUSINESS_PROFILE_REQUEST,
  payload: data,
});
export const getSingleBusinessProfileSuccess = (data) => ({
  type: Types.GET_SINGLE_BUSINESS_PROFILE_SUCCESS,
  payload: data,
});
export const getSingleBusinessProfileFailure = (data) => ({
  type: Types.GET_SINGLE_BUSINESS_PROFILE_FAILURE,
  payload: data,
});

export const approveDenyBusinessProfileRequest = (data) => ({
  type: Types.APPROVE_DENY_BUSINESS_PROFILE_REQUEST,
  payload: data,
});
export const approveDenyBusinessProfileSuccess = (data) => ({
  type: Types.APPROVE_DENY_BUSINESS_PROFILE_SUCCESS,
  payload: data,
});
export const approveDenyBusinessProfileFailure = (data) => ({
  type: Types.APPROVE_DENY_BUSINESS_PROFILE_FAILURE,
  payload: data,
});