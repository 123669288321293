import { call, put, takeEvery } from 'redux-saga/effects';
import * as actions from '../actions/categories.action';
import * as api from '../../api/categories.api';

function* getCategories() {
  try {
    const result = yield call(api.getCategories);

    if (result) {
      yield put(actions.getCategoriesSuccess(result));
    } else {
      yield put(actions.getCategoriesFailure(result));
    }
  } catch (error) {
    yield put(actions.getCategoriesFailure(error));
  }
}

function* createCategory(action) {
  try {
    const result = yield call(api.createCategory, action.payload);

    if (result) {
      yield put(actions.createCategorySuccess(result));
    } else {
      yield put(actions.createCategoryFailure(result));
    }
  } catch (error) {
    yield put(actions.createCategoryFailure(error));
  }
}

function* updateCategory(action) {
  try {
    const result = yield call(api.updateCategory, action.payload);

    if (result) {
      yield put(actions.updateCategorySuccess(result));
    } else {
      yield put(actions.updateCategoryFailure(result));
    }
  } catch (error) {
    yield put(actions.updateCategoryFailure(error));
  }
}

function* getSingleCategory(action) {
  try {
    const result = yield call(api.getSingleCategory, action.payload);

    if (result) {
      yield put(actions.getSingleCategorySuccess(result));
    } else {
      yield put(actions.getSingleCategoryFailure(result));
    }
  } catch (error) {
    yield put(actions.getSingleCategoryFailure(error));
  }
}

function* deleteCategory(action) {
  try {
    const result = yield call(api.deleteCategory, action.payload);

    if (result) {
      yield put(actions.deleteCategorySuccess(result));
    } else {
      yield put(actions.deleteCategoryFailure(result));
    }
  } catch (error) {
    yield put(actions.deleteCategoryFailure(error));
  }
}

const watchGetGategories = [
  takeEvery(actions.Types.GET_CATEGORIES_REQUEST, getCategories),
  takeEvery(actions.Types.CREATE_CATEGORY_REQUEST, createCategory),
  takeEvery(actions.Types.UPDATE_CATEGORY_REQUEST, updateCategory),
  takeEvery(actions.Types.DELETE_CATEGORY_REQUEST, deleteCategory),
  takeEvery(actions.Types.GET_SINGLE_CATEGORY_REQUEST, getSingleCategory),
];

export default watchGetGategories;
